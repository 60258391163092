export const LOGIN_API_PATH = "login/";
export const AUTH_USER_API_PATH = "auth_user/";

export const USERS_API_PATH = "users/";
export const USERS_DETAIL_API_PATH = `${USERS_API_PATH}{}/`;

export const AUCTIONS_API_PATH = "auctions/";
export const AUCTIONS_DETAIL_API_PATH = `${AUCTIONS_API_PATH}{}/`;
export const AUCTION_CITIES_API_PATH = `${AUCTIONS_API_PATH}auction_cities/`;
export const AUCTION_IMAGE_UPLOAD_API_PATH = `${AUCTIONS_API_PATH}upload_auction_image/`;
export const AUCTION_SOURCES_API_PATH = "auction_sources/";

export const AUCTION_ITEMS_API_PATH = "auction_items/";
export const AUCTION_ITEM_DETAIL_API_PATH = `${AUCTION_ITEMS_API_PATH}{}/`;
export const AUCTION_ITEM_IMAGE_UPLOAD_API_PATH = `${AUCTION_ITEMS_API_PATH}upload_image/`;
export const AUCTION_ITEM_DELETE_MEDIA_API_PATH = `${AUCTION_ITEMS_API_PATH}delete_media/?id={fileId}`;
export const AUCTION_ITEM_CUSTOMER_COLLECTIONS_SAVE_API_PATH = `${AUCTION_ITEM_DETAIL_API_PATH}save_customer_collections/`;
export const AUCTION_ITEM_CUSTOMER_COLLECTIONS_DELETE_API_PATH = `${AUCTION_ITEM_DETAIL_API_PATH}delete_customer_collections/`;

export const AUCTION_COLLECTIONS_API_PATH = "auction_collections/";
export const AUCTION_COLLECTION_DETAIL_API_PATH = `${AUCTION_COLLECTIONS_API_PATH}{}/`;
export const AUCTION_COLLECTION_IMAGE_UPLOAD_API_PATH = `${AUCTION_COLLECTIONS_API_PATH}upload_image/`;

export const JEWELRY_CLASSIFICATION_API_PATH = `jewelry_classification/`;
export const JEWELRY_CLASSIFICATION_DETAIL_API_PATH = `${JEWELRY_CLASSIFICATION_API_PATH}{}/`;
export const AUCTION_ITEMS_JEWELRY_LIST_API_PATH = `${JEWELRY_CLASSIFICATION_API_PATH}auction_item_jewelries/`;
export const JEWELRY_CLASSIFICATION_ATTRIBUTE_API_PATH = `${JEWELRY_CLASSIFICATION_DETAIL_API_PATH}attributes/`;
export const JEWELRY_CLASSIFICATION_ADD_ATTRIBUTE_API_PATH = `${JEWELRY_CLASSIFICATION_DETAIL_API_PATH}add_attribute/`;
export const JEWELRY_CLASSIFICATION_DELETE_ATTRIBUTE_API_PATH = `${JEWELRY_CLASSIFICATION_DETAIL_API_PATH}delete_attribute/`;
export const JEWELRY_CLASSIFICATION_ADD_ATTRIBUTE_DDL_VALUE_API_PATH = `${JEWELRY_CLASSIFICATION_DETAIL_API_PATH}add_attribute_ddl_value/`;
export const JEWELRY_CLASSIFICATION_DELETE_ATTRIBUTE_DDL_VALUE_API_PATH = `${JEWELRY_CLASSIFICATION_DETAIL_API_PATH}delete_attribute_ddl_value/`;

export const ITEM_SOURCES_API_PATH = "sources/";
export const ITEM_SOURCE_DETAIL_API_PATH = `${ITEM_SOURCES_API_PATH}{}/`;
export const ITEM_SOURCE_TYPES_API_PATH = `${ITEM_SOURCES_API_PATH}source_types/`;
export const ITEM_SOURCE_CUSTOMER_ITEMS_API_PATH = `${ITEM_SOURCES_API_PATH}customer_source_items/`;
export const ITEM_SOURCE_CUSTOMER_COLLECTIONS_API_PATH = `${ITEM_SOURCES_API_PATH}customer_collections/`;
export const ITEM_SOURCE_IMAGE_UPLOAD_PATH = `${ITEM_SOURCES_API_PATH}upload_image/`;
export const ITEM_SOURCE_IMAGE_DELETED_PATH = `${ITEM_SOURCES_API_PATH}delete_image/?id={fileId}`;
export const UNLINK_CUSTOMER_AUCTION_ITEM_PATH = `${ITEM_SOURCES_API_PATH}unlink_comparable_item/?customer_collection_customer_auction_item_id={customerCollectionCustomerAuctionItemId}`;

export const AUCTION_TAGS_API_PATH = "auction_item_tags/";
export const AUCTION_TAG_DETAIL_API_PATH = `${AUCTION_TAGS_API_PATH}{}/`;

export const TRADE_SHOWS_API_PATH = "trade_shows/";
export const TRADE_SHOW_DETAIL_API_PATH = `${TRADE_SHOWS_API_PATH}{}/`;
export const TRADE_SHOW_CITIES_API_PATH = `${TRADE_SHOWS_API_PATH}trade_show_cities/`;

export const OTP_API_PATH = "otp/";
export const GENERATE_QR_CODE = `${OTP_API_PATH}generate/`;
export const VERIFY_AUTH_CODE = `${OTP_API_PATH}verify/`;
export const DISABLE_2FA = `${OTP_API_PATH}disable/`;
export const VALIDATE_OTP = `${OTP_API_PATH}validate/`;

export const JEWELRY_CLASSIFICATION_ADD_ATTRIBUTE_LABEL_MAPPING = `attributes_label_mapping/`;

export const AUCTION_ITEM_FLAT_TABLE = 'auction_items_search/';

export const CREATE_CATEGORY_INDEXES = 'category_indexes/create_category/';

export const GET_CATEGORY_INDEXES = 'category_indexes/get_category_index/';

export const CATEGORY_INDEXES_UPDATE_AND_DELETE = 'category_indexes/update_delete_category/';

export const CATEGORY_INDEXES_LIST = 'category_indexes/';
