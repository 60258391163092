import React from "react";
import {
  UserOutlined,
  MoneyCollectOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";

import NotFoundPage from "pages/404Page";

import Login from "pages/login";
import ValidateOtp from "pages/validateOtp";

import DashboardLayout from "layouts/dashboard";
import { SALES_AGENT_OR_HIGHER_ROLES } from "global_constants";

const UsersComponent = React.lazy(() => import("pages/users"));

const AuctionManagementComponent = React.lazy(() =>
  import("pages/auctionManagement")
);
const AllAuctionItemListComponent = React.lazy(() =>
  import("pages/allAuctionItemList")
);
const AuctionItemListComponent = React.lazy(() =>
  import("pages/auctionItemManagement")
);
const CustomerAuctionItemListComponent = React.lazy(() =>
  import("pages/customerAuctionItemList")
);
const InventoryAuctionItemListComponent = React.lazy(() =>
  import("pages/inventoryAuctionItemList")
);
const DealerAuctionItemListComponent = React.lazy(() =>
  import("pages/dealerAuctionItemList")
);

const AuctionItemCreateOrEditComponent = React.lazy(() =>
  import("pages/auctionItemManagement/createOrEdit")
);
const AuctionCollectionManagementComponent = React.lazy(() =>
  import("pages/auctionCollectionManagement")
);
const AuctionTagManagementComponent = React.lazy(() =>
  import("pages/auctionTagManagement")
);

const SourceManagementComponent = React.lazy(() =>
  import("pages/sourceManagement")
);
const CustomerSourceManagementComponent = React.lazy(() =>
  import("pages/customerSourceManagement")
);
const DealerSourceManagementComponent = React.lazy(() =>
  import("pages/dealerSourceManagement")
);
const TradeShowManagementComponent = React.lazy(() =>
  import("pages/tradeShowManagement")
);

const LabelMappingComponent = React.lazy(() =>
  import("pages/labelMapping")
);

const CategoryIndexes = React.lazy(() =>
  import("pages/categoryIndexes")
);

const FilterCategoryIndexes = React.lazy(() =>
  import("pages/categoryIndexes/filterCategoryIndexes")
);

const CustomerCollectionManagementComponent = React.lazy(() =>
  import("pages/customerCollectionManagement")
);

const UserSettingsComponent = React.lazy(() =>
  import("pages/settings")
);

const HomePageComponet = React.lazy(() => import("pages/homePage"));

export const INDEX_ROUTE = "/";
export const SETTINGS_ROUTE = "/settings";
export const USERS_ROUTE = "/users/";
export const LABEL_MAPPING_ROUTE = "/label-mapping/";
export const CATEGORY_INDEXES_ROUTE = "/category-indexes"
export const FILTER_CATEGORY_INDEXES_ROUTE = "/filter-category-indexes"
export const AUCTION_ROUTE = "/auction-management/";

export const SOURCE_MANAGEMENT_ROUTE = "/sources/";
export const DEALER_MANAGEMENT_ROUTE = "/dealers/";
export const CUSTOMER_MANAGEMENT_ROUTE = "/customers/";

export const AUCTION_TAG_MANAGEMENT_ROUTE = "/auction-tags/";
export const AUCTION_COLLECTION_ROUTE = "/auction-collection/";

export const AUCTION_ITEM_ROUTE = "/auction-items/";
export const AUCTION_ITEM_ROUTE_SEARCH = "/auction-items-search/";
export const ALL_AUCTION_ITEM_ROUTE = "/all-auction-items/";
export const AUCTION_DEALER_ITEM_ROUTE = `/dealer-auction-items/`;
export const AUCTION_CUSTOMER_ITEM_ROUTE = `/customer-auction-items/`;
export const AUCTION_INVENTORY_ITEM_ROUTE = `/inventory-auction-items/`;
export const AUCTION_ITEM_CREATE_ROUTE = `${AUCTION_ITEM_ROUTE}create/`;
export const AUCTION_ITEM_EDIT_ROUTE = `${AUCTION_ITEM_ROUTE}:itemId/edit/`;

export const TRADE_SHOWS_ROUTE = "/trade-shows/";
export const CUSTOMER_COLLECTION_ROUTE = "/customer-collections/";

export const VALIDATE_OTP = "/validate-2fa/";

export const getAuctionItemEditRoute = (itemId) => {
  return AUCTION_ITEM_EDIT_ROUTE.replace(":itemId", itemId);
};

// ROUTES

export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "login",
    path: INDEX_ROUTE,
    component: Login,
  },
  {
    exact: true,
    path: VALIDATE_OTP,
    name: "validate-2fa",
    component: ValidateOtp,
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [
      {
        exact: true,
        name: "home",
        path: INDEX_ROUTE,
        component: HomePageComponet,
      },
      {
        exact: true,
        name: "settings",
        path: SETTINGS_ROUTE,
        component: UserSettingsComponent,
      },
      {
        exact: true,
        name: "users",
        path: USERS_ROUTE,
        component: UsersComponent,
      },
      {
        exact: true,
        name: "auction-list",
        path: AUCTION_ROUTE,
        component: AuctionManagementComponent,
      },
      {
        exact: true,
        name: "auction-item-list",
        path: AUCTION_ITEM_ROUTE,
        component: AuctionItemListComponent,
      },
      {
        exact: true,
        name: "auction-item-list",
        path: AUCTION_ITEM_ROUTE_SEARCH,
        component: AuctionItemListComponent,
      },
      {
        exact: true,
        name: "all-auction-item-list",
        path: ALL_AUCTION_ITEM_ROUTE,
        component: AllAuctionItemListComponent,
      },
      {
        exact: true,
        name: "auction-customer-item-list",
        path: AUCTION_CUSTOMER_ITEM_ROUTE,
        component: CustomerAuctionItemListComponent,
      },
      {
        exact: true,
        name: "auction-inventory-item-list",
        path: AUCTION_INVENTORY_ITEM_ROUTE,
        component: InventoryAuctionItemListComponent,
      },
      {
        exact: true,
        name: "auction-dealer-item-list",
        path: AUCTION_DEALER_ITEM_ROUTE,
        component: DealerAuctionItemListComponent,
      },
      {
        exact: true,
        name: "auction-item-create",
        path: AUCTION_ITEM_CREATE_ROUTE,
        component: AuctionItemCreateOrEditComponent,
      },
      {
        exact: true,
        name: "auction-item-edit",
        path: AUCTION_ITEM_EDIT_ROUTE,
        component: AuctionItemCreateOrEditComponent,
      },
      {
        exact: true,
        name: "auction-collection-list",
        path: AUCTION_COLLECTION_ROUTE,
        component: AuctionCollectionManagementComponent,
      },
      {
        exact: true,
        name: "customer-management",
        path: CUSTOMER_MANAGEMENT_ROUTE,
        component: CustomerSourceManagementComponent,
      },
      {
        exact: true,
        name: "source-management",
        path: SOURCE_MANAGEMENT_ROUTE,
        component: SourceManagementComponent,
      },
      {
        exact: true,
        name: "dealer-management",
        path: DEALER_MANAGEMENT_ROUTE,
        component: DealerSourceManagementComponent,
      },
      {
        exact: true,
        name: "tag-management",
        path: AUCTION_TAG_MANAGEMENT_ROUTE,
        component: AuctionTagManagementComponent,
      },
      {
        exact: true,
        path: CUSTOMER_COLLECTION_ROUTE,
        name: "customer-collection-management",
        component: CustomerCollectionManagementComponent,
      },
      {
        exact: true,
        name: "trade-show-management",
        path: TRADE_SHOWS_ROUTE,
        component: TradeShowManagementComponent,
      },
      {
        exact: true,
        name: "label-mapping",
        path: LABEL_MAPPING_ROUTE,
        component: LabelMappingComponent,
      },
      {
        exact: true,
        name: "category-indexes",
        path: CATEGORY_INDEXES_ROUTE,
        component: CategoryIndexes,
      },
      {
        exact: true,
        name: "filter-category-index",
        path: FILTER_CATEGORY_INDEXES_ROUTE,
        component: FilterCategoryIndexes,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  // {
  //   name: "home",
  //   label: "Home",
  //   icon: <DeploymentUnitOutlined />,
  //   route: INDEX_ROUTE,
  // },
  {
    name: "all-auction-items",
    label: "All Items",
    icon: <DeploymentUnitOutlined />,
    route: ALL_AUCTION_ITEM_ROUTE,
  },
  {
    label: "Inventory Items",
    name: "auction-inventory-items",
    icon: <DeploymentUnitOutlined />,
    route: AUCTION_INVENTORY_ITEM_ROUTE,
  },
  {
    name: "auction",
    label: "Auctions",
    icon: <DeploymentUnitOutlined />,
    submenu: [
      {
        name: "auctions",
        label: "Auctions",
        route: AUCTION_ROUTE,
        icon: <DeploymentUnitOutlined />,
      },
      {
        name: "auction-items",
        label: "Auction Items",
        route: AUCTION_ITEM_ROUTE,
        icon: <DeploymentUnitOutlined />,
      },
    ],
  },
  {
    name: "dealer",
    label: "Dealer",
    icon: <DeploymentUnitOutlined />,
    submenu: [
      {
        name: "dealers",
        label: "Dealers",
        icon: <MoneyCollectOutlined />,
        route: DEALER_MANAGEMENT_ROUTE,
      },
      {
        label: "Dealer Items",
        name: "auction-dealer-items",
        icon: <DeploymentUnitOutlined />,
        route: AUCTION_DEALER_ITEM_ROUTE,
      },
      {
        name: "trade-shows",
        label: "Tradeshows",
        route: TRADE_SHOWS_ROUTE,
        icon: <MoneyCollectOutlined />,
      },
    ],
  },
  {
    name: "customer",
    label: "Customer",
    icon: <DeploymentUnitOutlined />,
    rolesAllowed: SALES_AGENT_OR_HIGHER_ROLES,
    submenu: [
      {
        name: "customers",
        label: "Customers",
        icon: <MoneyCollectOutlined />,
        route: CUSTOMER_MANAGEMENT_ROUTE,
      },
      {
        label: "Customer Items",
        name: "auction-customer-items",
        icon: <DeploymentUnitOutlined />,
        route: AUCTION_CUSTOMER_ITEM_ROUTE,
      },
      {
        name: "customer-collections",
        label: "Customer Collection",
        icon: <MoneyCollectOutlined />,
        route: CUSTOMER_COLLECTION_ROUTE,
      },
    ],
  },
  // {
  //   name: "sources",
  //   label: "Sources",
  //   icon: <MoneyCollectOutlined />,
  //   route: SOURCE_MANAGEMENT_ROUTE,
  // },
  {
    name: "tags",
    label: "Tags",
    icon: <MoneyCollectOutlined />,
    route: AUCTION_TAG_MANAGEMENT_ROUTE,
  },
  {
    name: "user",
    label: "Users",
    route: USERS_ROUTE,
    icon: <UserOutlined />,
    rolesAllowed: ["Administrator"]
  },
  {
    name: "label-mapping",
    label: "Label Mapping",
    route: LABEL_MAPPING_ROUTE,
    icon: <MoneyCollectOutlined />,
    rolesAllowed: ["Administrator"]
  },
  {
    name: "category-indexes",
    label: "Indexes",
    route: CATEGORY_INDEXES_ROUTE,
    icon: <MoneyCollectOutlined />,
    rolesAllowed: ["Administrator"]
  },
  // {
  //   name: "auction-collection",
  //   label: "Auction Collection",
  //   icon: <MoneyCollectOutlined />,
  //   route: AUCTION_COLLECTION_ROUTE,
  // },
];
