export const AUTHORIZATION_KEY = "jewelry_portal_token";

export const DEFAULT_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
};

export const ITEM_SOURCE_COMPANY_TYPE_OPTIONS = [
  "Designer",
  "Refiner",
  "Appraiser",
  "Pawn Shop",
  "Gold Buyer",
  "Laboratory",
  "Auctioneer",
  "Watch Dealer",
  "Diamond Mining",
  "Trade Association",
  "Independent Jeweler",
  "Jewelry Retail Chain",
  "Online Jewelry Store",
  "Diamond Dealer/Broker",
  "Jewelry Manufacturer",
  "Rough Diamond Dealer",
  "Diamond Manufacturer/Cutter",
];

// if this changes - we need to change the enum values in backend
export const DELAER_SOURCE_TYPE = "dealer";
export const AUCTION_SOURCE_TYPE = "auction";
export const CUSTOMER_SOURCE_TYPE = "customer";
export const TRADE_SHOW_SOURCE_TYPE = "trade-show";
export const SHS_INVENTORY_SOURCE_TYPE = "shs-inventory";
export const DEALER_REPERSENTATIVE_SOURCE_TYPE = "dealer-representative";

export const DDL_FILTER = "ddl";
export const RANGE_ALPHA_FILTER = "range-alpha";
export const RANGE_NUMERIC_FILTER = "range-numeric";

export const TEXT_ATTRIBUTE_TYPE = "text";
export const NUMBER_ATTRIBUTE_TYPE = "number";
export const TEXTAREA_ATTRIBUTE_TYPE = "textarea";
export const PICK_LIST_ATTRIBUTE_TYPE = "pick list";

export const SALES_AGENT_OR_HIGHER_ROLES = ["Administrator", "SalesAgent"]