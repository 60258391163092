import { message } from "antd";

export const isImageVideoFormatAndSizeOk = (file) => {
  const isImageFormatOk = file.type.includes("image/");
  const isVideoFormatOk = file.type.includes("video/");
  if (!isImageFormatOk && !isVideoFormatOk) {
    message.error("You can only upload files of image or video type!");
    return false;
  }
  const megabytesLimit = 500;
  const isSizeOk = file.size / 1024 / 1024 < megabytesLimit;
  if (!isSizeOk) {
    message.error(`File must be smaller than ${megabytesLimit} MB!`);
    return false;
  }
  return true;
}

export const DollarFormatter = (value) => {
  if (value === null || value === undefined) return value;
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const GetNumericValue = (str) => {
  if (isNaN(str)) return null;
  try { return parseFloat(str); }
  catch (e) { return parseInt(str); }
}

export const ReturnCorrectedImageUrl = (url) => {
  if (url === null || url === undefined) return url;
  if (url && url.includes("http://127.0.0.1/")) {
    return url.replace("http://127.0.0.1/", `${process.env.REACT_APP_API_HOST}/`);
  }
  if (url && url.includes("http://localhost:8000/")) {
    return url.replace("http://localhost:8000/", `${process.env.REACT_APP_API_HOST}/`);
  }
  return url;
}

export const ReturnCorrectedVideoUrl = (url) => {
  if (url === null || url === undefined) return url;
  if (url && url.includes("http://localhost:8000/")) {
    return url.replace("http://localhost:8000/", `${process.env.REACT_APP_API_HOST}/`);
  }
  return url;
}

export const IsUserAccessAllowed = (accountData, allowedRoles) => {
  let isAllowed = false;

  // check if allowed roles is an array
  if (!Array.isArray(allowedRoles)) {
    return false;
  }

  allowedRoles.forEach((role) => {
    if ((accountData?.user_role || []).map((x) => x.role).includes(role)) {
      isAllowed = true;
    }
  });

  return isAllowed;
}