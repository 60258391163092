import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Card, Form, Input, Button, message } from "antd";
import { validateAuthCode } from "actions";
import { AUTHORIZATION_KEY } from "global_constants";
import AccountHook from "hooks/account";

const ValidateOtp = ({location, setAccountData}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    if (!location.state?.user_id) {
      history.push("/");
    }
    //! To reset the state and redirecting to login page after refreshing the page
    // window.history.replaceState({}, document.title)
  });

  function onSubmit(values) {
    setVerifying(true);

    validateAuthCode({
      otp_token: values.token,
      user_id: location.state?.user_id,
    }).then(({ data }) => {
      if (data.otp_valid) {
        setAccountData(data);
        localStorage.setItem(AUTHORIZATION_KEY, data.token);
        history.push('/auction-management');
      } else {
        history.push('/');
      }
      
    }).catch(err => {
      setVerifying(false);
      message.error(err.response?.data?.error || "Error occurred");
    })
  }

  return (
    <div className="login-page">
      <Card bordered>
        <Form
          form={form}
          onFinish={(values) => onSubmit(values)}
        >
          <div className="mb-4 text-center">
            <h2 className="text-3xl semi-bold">Two-Factor Authentication</h2>
            <p className="text-small">
              Open the two-step verification app on your mobile device to get
              your verification code.
            </p>
          </div>
          <Form.Item
            name="token"
            rules={[
              {
                required: true,
                message: "Please input code!",
              },
            ]}
          >
            <Input size="middle" placeholder="Authentication Code" />
          </Form.Item>
          <Form.Item noStyle>
            <Button
              block
              type="primary"
              className="mt-8"
              htmlType="submit"
              loading={verifying}
            >
              Authenticate
            </Button>
          </Form.Item>
          <span className="block text-center text-link mt-4">
            <Link to="/" className="text-ct-blue-600">
              Back to basic login
            </Link>
          </span>
        </Form>
      </Card>
    </div>
  );
};
export default AccountHook(ValidateOtp);
