import { useEffect, useState } from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { Avatar, Col, Row, Space, Menu, Dropdown, Layout } from "antd";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined
} from "@ant-design/icons";

import { AUTHORIZATION_KEY } from "global_constants";
import { INDEX_ROUTE, SIDEBAR_ITEMS } from "routes";

import AccountHook from "hooks/account";
import RenderRoutes from "components/renderRoutes";
import { IsUserAccessAllowed } from "utilities/genericHelpers";

const { Header, Content, Sider } = Layout;

const DashboardLayout = ({ routes, accountData, setAccountData }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState({});

  useEffect(() => {
    let items = [];
    SIDEBAR_ITEMS.forEach((item) => {
      if (item.submenu) {
        items = [...items, ...item.submenu];
      } else {
        items.push(item);
      }
    });
    items.forEach((item) => {
      const matchedPath = matchPath(pathname, {
        path: item.route,
        exact: true,
      });
      if (matchedPath) {
        if (selectedMenu.name !== item.name) {
          setSelectedMenu(item);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function logout() {
    setAccountData({});
    history.push(INDEX_ROUTE);
    localStorage.removeItem(AUTHORIZATION_KEY);
  }

  return (
    <Layout className="app-layout">
      <Sider trigger={null} collapsible collapsed={collapsed} width={225}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" selectedKeys={[selectedMenu.name]}>
          {SIDEBAR_ITEMS.map((item) => {
            if (item.rolesAllowed) {
              if (!IsUserAccessAllowed(accountData, item.rolesAllowed)) {
                return null;
              }
            }
            if (item.submenu) {
              return (
                <Menu.SubMenu
                  key={item.name}
                  title={
                    <>
                      {item.icon}
                      <span>{item.label}</span>
                    </>
                  }
                >
                  {item.submenu.map((submenuItem) => {
                    return (
                      <Menu.Item
                        key={submenuItem.name}
                        onClick={() => history.push(submenuItem.route)}
                      >
                        {submenuItem.label}
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item
                key={item.name}
                onClick={() => history.push(item.route)}
              >
                {item.icon}
                <span>{item.label}</span>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout>
        <Header>
          <Row justify="space-between" align="middle">
            <Col span={4}>
              <Row justify="space-between">
                <Col>
                <Choose>
                  <When condition={collapsed}>
                    <MenuUnfoldOutlined
                      onClick={() => setCollapsed(!collapsed)}
                    />
                  </When>
                  <Otherwise>
                    <MenuFoldOutlined onClick={() => setCollapsed(!collapsed)} />
                  </Otherwise>
                </Choose>
                </Col>
                <If condition={process.env.REACT_APP_PORTAL_ENVIRONMENT === 'development'}>
                  <Col span={12}>
                    <h1>Dev</h1>
                  </Col>
                </If>
              </Row>
            </Col>
            <Col>
              <Dropdown
                arrow
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item key="settings" onClick={() => history.push('/settings')}>
                      <Space>
                        <SettingOutlined />
                        <p className="text-xl">Settings</p>
                      </Space>
                    </Menu.Item>
                    <Menu.Item key="logout" onClick={() => logout()}>
                      <Space>
                        <LogoutOutlined />
                        <p className="text-xl">Logout</p>
                      </Space>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Space className="header-menu">
                  <Avatar>{accountData.initials}</Avatar>
                  <p className="text-xl">{accountData.full_name}</p>
                </Space>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content>
          <div className="feature-container">
            <RenderRoutes routes={routes} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AccountHook(DashboardLayout);
