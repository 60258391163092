/* eslint-disable no-useless-escape */
import dayjs from "dayjs";

function dataValidateWrapper(data, field) {
  return !!(
    (!isEmpty(data) ||
      data[field] ||
      data[field] === 0 ||
      typeof data[field] === "boolean") &&
    data[field] != null &&
    typeof data[field] != "undefined"
  );
}

export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const isEmptyObj = (obj) => {
  for (const key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== "" &&
      obj[key] !== false &&
      obj[key] !== undefined
    )
      return false;
  }
  return true;
};

export const allowOnlyIntegers = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }
};

export const roundOff = (value) => {
  return Math.round(value * 100) / 100;
};

export const random = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const range = (start, end) =>
  Array.from({ length: end + 1 - start }, (v, k) => k + start);

export const sort = (data, sortKey) => {
  return data.sort((a, b) => {
    return (
      (a[sortKey] === null) - (b[sortKey] === null) ||
      +(a[sortKey] > b[sortKey]) ||
      -(a[sortKey] < b[sortKey])
    );
  });
};

export const sortAlphabetically = (data, sortKey) => {
  return data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
};
export const sortAlphabeticallyFlat = (data) => {
  return data.sort((a, b) => a.localeCompare(b));
};

export const getKeyValue = (objectData, key, defaultValue = "") => {
  var objectKeys = typeof key != "undefined" ? key.split("__") : [];
  var objectValue = objectData;
  if (!isEmpty(objectData)) {
    objectKeys.forEach((objectKey) => {
      if (dataValidateWrapper(objectValue, objectKey)) {
        objectValue = objectValue[objectKey];
      } else {
        objectValue = "";
      }
    });
    if (objectValue || objectValue === 0 || typeof objectValue === "boolean") {
      return objectValue;
    } else {
      return defaultValue || typeof defaultValue === "boolean"
        ? defaultValue
        : "";
    }
  } else {
    return defaultValue || typeof defaultValue === "boolean"
      ? defaultValue
      : "";
  }
};

export const getAttributeFiltersQueryString = (values) => {
  const filtersArray = [];
  Object.keys(values).forEach((key) => {
    let valueString = "";
    if (Array.isArray(values[key])) {
      valueString = values[key].join("|");
    } else {
      valueString = values[key].split("|").join("|");
    }
    filtersArray.push(`${key}=${valueString}`);
  });
  return filtersArray.join("&");
};

export const getAuctionDateRange = (record) => {
  let value = "";
  if (record.start_date) {
    value = `${dayjs(record.start_date).format("MM/DD/YYYY")}`;
  }
  if (record.end_date) {
    value = `${value} - ${dayjs(record.end_date).format("MM/DD/YYYY")}`;
  }
  return value ? value : "-";
};

export const getTableSortValue = (sorter, colKey) => {
  return sorter === colKey
    ? "ascend"
    : sorter === `-${colKey}`
      ? "descend"
      : false;
};
