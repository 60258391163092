import axios from "axios";
import instance from "../axios";
import * as endpoints from "./endpoints";
import { AUTHORIZATION_KEY } from "global_constants";

export const getAuctionImageUploadPath = () => {
  return `${instance.defaults.baseURL}${endpoints.AUCTION_IMAGE_UPLOAD_API_PATH}`;
};

export const getAuctionItemImageUploadPath = () => {
  return `${instance.defaults.baseURL}${endpoints.AUCTION_ITEM_IMAGE_UPLOAD_API_PATH}`;
};


export const getItemSourceImageUploadPath = () => {
  return `${instance.defaults.baseURL}${endpoints.ITEM_SOURCE_IMAGE_UPLOAD_PATH}`;
};

export const getAuctionCollectionImageUploadPath = () => {
  return `${instance.defaults.baseURL}${endpoints.AUCTION_COLLECTION_IMAGE_UPLOAD_API_PATH}`;
};

const cancelInFlightGetRequest = (path) => {
  const cancelTokenSource = axios.CancelToken.source();
  return {
    request: instance.get(path, {
      cancelToken: cancelTokenSource.token,
    }),
    cancelTokenSource,
  };
};

export const deleteItemSourceFile = (fileId) => {
  return instance.delete(
    endpoints.ITEM_SOURCE_IMAGE_DELETED_PATH.replace("{fileId}", fileId)
  );
}

export const unlinkCustomerAuctionItem = (customerCollectionCustomerAuctionItemId) => {
  return instance.post(
    endpoints.UNLINK_CUSTOMER_AUCTION_ITEM_PATH.replace("{customerCollectionCustomerAuctionItemId}", customerCollectionCustomerAuctionItemId)
  );
}

export const loginUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAuthUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance
        .get(endpoints.AUTH_USER_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const getUsers = (queryString) => {
  let path = endpoints.USERS_API_PATH;
  path = path.concat("?", queryString || "");
  return instance.get(path);
};

export const createUser = (payload) => {
  return instance.post(endpoints.USERS_API_PATH, payload);
};

export const updateUser = (payload, userId) => {
  return instance.patch(
    endpoints.USERS_DETAIL_API_PATH.replace("{}", userId),
    payload
  );
};

export const getAuctions = (queryString) => {
  let path = endpoints.AUCTIONS_API_PATH;
  path = path.concat("?", queryString || "");
  return instance.get(path);
};

export const getAuctionCities = () => {
  return instance.get(endpoints.AUCTION_CITIES_API_PATH);
};

export const createAuction = (payload) => {
  return instance.post(endpoints.AUCTIONS_API_PATH, payload);
};

export const editAuction = (payload, auctionId) => {
  return instance.patch(
    endpoints.AUCTIONS_DETAIL_API_PATH.replace("{}", auctionId),
    payload
  );
};

export const getAuctionSources = () => {
  return instance(endpoints.AUCTION_SOURCES_API_PATH);
};

export const getAuctionItems = (queryString) => {
  let path = endpoints.AUCTION_ITEMS_API_PATH;
  path = path.concat("?", queryString || "");
  return cancelInFlightGetRequest(path);
};

export const getAuctionItemDetail = (itemId) => {
  const path = endpoints.AUCTION_ITEM_DETAIL_API_PATH;
  return instance.get(path.replace("{}", itemId));
};

export const saveAuctionItemCustomerCollections = (itemId, payload) => {
  const path = endpoints.AUCTION_ITEM_CUSTOMER_COLLECTIONS_SAVE_API_PATH;
  return instance.post(path.replace("{}", itemId), payload);
};

export const deleteAuctionItemCustomerCollection = (itemId, parentId) => {
  const path = `${endpoints.AUCTION_ITEM_CUSTOMER_COLLECTIONS_DELETE_API_PATH}${parentId}/`;
  return instance.delete(path.replace("{}", itemId));
};

export const createAuctionItem = (payload) => {
  return instance.post(endpoints.AUCTION_ITEMS_API_PATH, payload);
};

export const editAuctionItem = (payload, itemId) => {
  const path = endpoints.AUCTION_ITEM_DETAIL_API_PATH;
  return instance.patch(path.replace("{}", itemId), payload);
};

export const getJewelryClassificiations = () => {
  return instance.get(endpoints.JEWELRY_CLASSIFICATION_API_PATH);
};

export const getAuctionItemJewelries = () => {
  return instance.get(endpoints.AUCTION_ITEMS_JEWELRY_LIST_API_PATH);
};

export const getJewelryClassificiationAttributes = (classificationId) => {
  const path = endpoints.JEWELRY_CLASSIFICATION_ATTRIBUTE_API_PATH;
  return instance.get(path.replace("{}", classificationId));
};

export const addJewelryClassificationAttribute = (
  classificationId,
  payload
) => {
  const path = endpoints.JEWELRY_CLASSIFICATION_ADD_ATTRIBUTE_API_PATH;
  return instance.post(path.replace("{}", classificationId), payload);
};

export const deleteJewelryClassificationAttribute = (
  classificationId,
  payload
) => {
  const path = endpoints.JEWELRY_CLASSIFICATION_DELETE_ATTRIBUTE_API_PATH;
  return instance.post(path.replace("{}", classificationId), payload);
};

export const addJewelryClassificationAttrDDlValue = (
  classificationId,
  payload
) => {
  return instance.post(
    endpoints.JEWELRY_CLASSIFICATION_ADD_ATTRIBUTE_DDL_VALUE_API_PATH.replace(
      "{}",
      classificationId
    ),
    payload
  );
};

export const deleteJewelryClassificationAttrDDlValue = (
  classificationId,
  payload
) => {
  return instance.post(
    endpoints.JEWELRY_CLASSIFICATION_DELETE_ATTRIBUTE_DDL_VALUE_API_PATH.replace(
      "{}",
      classificationId
    ),
    payload
  );
};

export const updateLabelMapping = (payload) => {
  return instance.post(endpoints.JEWELRY_CLASSIFICATION_ADD_ATTRIBUTE_LABEL_MAPPING, payload);
};

export const addCategoryIndexes = (payload) => {
  return instance.post(endpoints.CREATE_CATEGORY_INDEXES, payload);
};

export const getCategoryIndexes = (payload) => {
  return instance.get(endpoints.GET_CATEGORY_INDEXES, payload);
};

export const updateAndDeleteIndexes = (payload) => {
  return instance.post(endpoints.CATEGORY_INDEXES_UPDATE_AND_DELETE, payload);
};

export const getCategoryIndexesList = (queryString) => {
  let path = endpoints.CATEGORY_INDEXES_LIST;
  path = path.concat("?", queryString || "");
  return cancelInFlightGetRequest(path);
};

export const getAuctionCollections = (queryString) => {
  let path = endpoints.AUCTION_COLLECTIONS_API_PATH;
  path = path.concat("?", queryString || "");
  return instance.get(path);
};

export const createAuctionCollection = (payload) => {
  return instance.post(endpoints.AUCTION_COLLECTIONS_API_PATH, payload);
};

export const editAuctionCollection = (payload, collectionId) => {
  return instance.patch(
    endpoints.AUCTION_COLLECTION_DETAIL_API_PATH.replace("{}", collectionId),
    payload
  );
};

export const getItemSources = (queryString) => {
  let path = endpoints.ITEM_SOURCES_API_PATH;
  path = path.concat("?", queryString || "");
  return instance.get(path);
};

export const createItemSource = (payload) => {
  return instance.post(endpoints.ITEM_SOURCES_API_PATH, payload);
};

export const editItemSource = (payload, itemId) => {
  return instance.patch(
    endpoints.ITEM_SOURCE_DETAIL_API_PATH.replace("{}", itemId),
    payload
  );
};

export const getItemSourceTypes = (queryString) => {
  let path = endpoints.ITEM_SOURCE_TYPES_API_PATH;
  path = path.concat("?", queryString || "");
  return instance.get(path);
};

export const getItemSourceCustomerItems = (queryString) => {
  let path = endpoints.ITEM_SOURCE_CUSTOMER_ITEMS_API_PATH;
  path = path.concat("?", queryString || "");
  return instance.get(path);
};

export const getAuctionItemTags = (queryString) => {
  let path = endpoints.AUCTION_TAGS_API_PATH;
  path = path.concat("?", queryString || "");
  return instance.get(path);
};

export const createAuctionItemTag = (payload) => {
  return instance.post(endpoints.AUCTION_TAGS_API_PATH, payload);
};

export const editAuctionItemTag = (payload, itemId) => {
  return instance.patch(
    endpoints.AUCTION_TAG_DETAIL_API_PATH.replace("{}", itemId),
    payload
  );
};

export const getCustomerCollections = (queryString) => {
  let path = endpoints.ITEM_SOURCE_CUSTOMER_COLLECTIONS_API_PATH;
  path = path.concat("?", queryString || "");
  return instance.get(path);
};


export const getTradeShows = (queryString) => {
  let path = endpoints.TRADE_SHOWS_API_PATH;
  path = path.concat("?", queryString || "");
  return instance.get(path);
};

export const createTradeShow = (payload) => {
  return instance.post(endpoints.TRADE_SHOWS_API_PATH, payload);
};

export const editTradeShow = (payload, itemId) => {
  return instance.patch(
    endpoints.TRADE_SHOW_DETAIL_API_PATH.replace("{}", itemId),
    payload
  );
};

export const getTradeShowCities = () => {
  return instance.get(endpoints.TRADE_SHOW_CITIES_API_PATH);
};

export const deleteMediaFile = (fileId) => {
  return instance.delete(
    endpoints.AUCTION_ITEM_DELETE_MEDIA_API_PATH.replace("{fileId}", fileId)
  );
}

export const generateQRCode = ({email, user_id }) => {
  return instance.post(endpoints.GENERATE_QR_CODE, { email, user_id})
}

export const verifyAuthCode = ({otp_token, user_id}) => {
  return instance.post(endpoints.VERIFY_AUTH_CODE, { otp_token, user_id})
}

export const disable2FA = ({otp_token, user_id }) => {
  return instance.post(endpoints.DISABLE_2FA, { otp_token, user_id })
}

export const validateAuthCode = ({otp_token, user_id }) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.VALIDATE_OTP, { otp_token, user_id })
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
  // return instance.post(endpoints.VALIDATE_OTP, { otp_token, user_id });
}

export const getAuctionItemsFromFlatTable = (queryString) => {
  let path = endpoints.AUCTION_ITEM_FLAT_TABLE;
  path = path.concat("?", queryString || "");
 return cancelInFlightGetRequest(path);
};
